import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import './style/carousel.css';


interface IEvent {
    name: string;
    date: string;
    location: string;
    url: string;
    img_url: string;
}


interface ICarouselProps {
    dateString: string;
    events: IEvent[];
    setSelectedEvent?: (event: IEvent) => void;
}


function Carousel( props: ICarouselProps ) {

  const navigate = useNavigate();

  return (
    <div className='date-events'>
      <div className='date-title'>{DateTime.fromISO(props.dateString).toFormat('EEEE dd LLL yyyy')}</div>
      <div className='carousel'>
      { props.events.length > 0 &&
        props.events.map( (event, index) => {
          return ( 
            <div key={"event"+index} className="carousel-item"
              onClick={ () => {
                  navigate('/event');
                  props.setSelectedEvent(event);
              }}
            >
              <div className="event-image">
                <img src={event.img_url} alt='' />
              </div>
              <div className="event-location">{event.location.slice(0, 14)}</div>
            </div>
          )
        })
      }
      </div>
    </div>
  )
}


export {
    Carousel,
    ICarouselProps,
    IEvent
}