import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { IEvent } from './Carousel';
import './style/eventModal.css';


interface IEventModalProps {
    event: IEvent;
}


function EventModal( props: IEventModalProps ) {

  const navigate = useNavigate();
  const [imgMaxHeight, setImgMaxHeight] = useState<number>(600);

  useEffect( () => {
      setImgMaxHeight(window.innerHeight/100*75);
  }, []);

  return (
    <div className='modal-container' > 
      <div className='modal-shadow-layer'
        onClick={() => {
            navigate(-1);
        }}
      ></div>
      <div key="event-modal" className="event-modal">
        <div className="event-modal-image">
          <img src={props.event.img_url} alt='' style={{ maxHeight: `${imgMaxHeight}px` }} />
        </div>
        <div className="event-modal-location">At {props.event.location},</div>
        <div className="event-modal-name">{props.event.name}</div>
        <div className="event-date">{ DateTime.fromISO(props.event.date).toFormat('EEEE dd LLL yyyy') }</div>
        <div className="event-link">
          <a href={props.event.url} target='_blank' rel="noreferrer">Find out more</a>
        </div>
      </div>
    </div>
  )
}


export {
    EventModal
}