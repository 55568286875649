import axios from 'axios';
import { DateTime } from 'luxon';


const be_base_url = "REACT_APP_BE_BASE_URL";


async function getEvents(from?: string, to?: string) {
    try {
        let now = DateTime.now();
        const params = {
            from_date: from ? from : now.toISODate(),
            to_date: to ? to : now.plus({ years: 1 }).toISODate()
        };
        const response = await axios.get(be_base_url+'/get-events', { params });
        return response && response.status === 200 ? response.data.events : [];
    }
    catch (e) {
        console.error('Error:', e);
    }
}


export {
    getEvents
}