import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import CarouselsList from './CarouselsList';
import { IEvent } from './Carousel';
import { EventModal } from './EventModal';


function App() {

    const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);

    return (
        <Router>
            <div className="App">
                <HeaderSection />
                <CarouselsList setSelectedEvent={setSelectedEvent} />
            </div>
            <Routes>
                <Route path='/' element={<div style={{display: 'none'}}></div>} />
                <Route path="/event" element={
                    selectedEvent ? <EventModal event={selectedEvent}/> : <Navigate replace to={"/"}/>
                }/>
            </Routes>
        </Router>
    );
}

export default App;