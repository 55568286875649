import React, { useState, useEffect } from 'react';
import './style/carouselsList.css';
import { Rings } from "react-loader-spinner";
import { Carousel, ICarouselProps, IEvent } from './Carousel';
import { getEvents } from './Calls';
import { DateTime } from 'luxon';


interface ICarouselListProps {
    setSelectedEvent: (event: IEvent) => void;
}


function CarouselsList(props: ICarouselListProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState<number>(window.scrollY);
    const [lastCallTimestamp, setLastCallTimestamp] = useState<number>(0);
    const [from, setFrom] = useState<DateTime>(DateTime.now());
    const [to, setTo] = useState<DateTime>(DateTime.now().plus({ weeks: 1 }));
    const [carousels, setCarousels] = useState<ICarouselProps[]>([]);

    async function loadMoreCarousels() {

        setLoading(true);

        let events = await getEvents(from.toISODate(), to.toISODate()) ?? [];
        let newCarousels: ICarouselProps[] = events.length > 0 ? getCarousels(events) : [];

        setCarousels( [...carousels, ...newCarousels] );

        setFrom( to.plus({ days: 1 }) );
        setTo( to.plus({ weeks: 1 }) );

        setLoading(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const scrollHeight = document.documentElement.scrollHeight;
        if ( (window.innerHeight + window.scrollY) > (scrollHeight * 0.9) ) {
            const now = Date.now();
            if ( (Date.now() - lastCallTimestamp) > 2000 ) {
                setLastCallTimestamp(now);
                loadMoreCarousels();
            }
        }
    }, [scrollY]);

    return (
        <div className="carousels-list">
            {carousels.map( (c, index) => (
                <div key={index} className="item">
                    <Carousel key={"carousel-"+index} dateString={c.dateString} events={c.events} setSelectedEvent={props.setSelectedEvent} />
                </div>
            ))}
            { loading && carousels.length === 0 &&
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <Rings
                        height="200"
                        width="200"
                        color="#A00"
                    />
                </div>
            }
        </div>
    );
};


function getCarousels(events: IEvent[]) {
    let carousels = events.reduce( (res: ICarouselProps[], event: IEvent) => {
        const dateString = event.date.slice(0, 10);
        if ( ! res.some( (e: any) => e.dateString === dateString ) ) {
            res.push( { dateString: dateString, events: [] } );
        }
        res = res.map( (e: ICarouselProps) => {
            if (e.dateString === dateString) {
                e.events.push(event);
            }
            return e;
        } );
        return res;
    }, [] ) // [] is the inital value of res
    carousels.sort( (a: ICarouselProps, b: ICarouselProps) => a.dateString.localeCompare(b.dateString) );
    return carousels;
}


export default CarouselsList;