import React from 'react';
import './style/headerSection.css';


function HeaderSection() {
    return (
      <div className="header-div">
          <div className="title-div">Milano By Night</div>
          <div className="second-title-div">Find night events in Milan</div>
      </div>
    );
}

export default HeaderSection;